<template>
  <div class="auth__body">
    <LoginForm @success="onSuccess" />
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()

const myProfileRoute = useMyProfileRoute()

function onSuccess () {
  router.push(route.query.redirect ?? myProfileRoute.value)
}
</script>

<style scoped lang="scss">

</style>
